import React from 'react';
import { Line } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const HistoryDataLog = ({ historyData }) => {
    // Prepara los datos para Chart.js
    const data = {
      labels: historyData.map(entry => entry.date), // Corregido a 'entry.date'
      datasets: [
        {
          label: 'Batería',
          data: historyData.map(entry => entry.battery),
          fill: true,
          borderColor: 'rgb(255, 99, 132)',
        },
        {
          label: 'Suministro de Energía',
          data: historyData.map(entry => entry.power_supply), // Asumiendo que es un valor numérico
          fill: true,
          borderColor: 'rgb(54, 162, 235)',
        },
        {
          label: 'Solar',
          data: historyData.map(entry => entry.solar), // Asumiendo que es un valor numérico
          fill: true,
          borderColor: 'rgb(75, 192, 192)',
        }
      ]
    };
  
    const options = {
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              parser: 'DD/MM/YYYY HH:mm:ss',
              tooltipFormat: 'll HH:mm'
            },
            scaleLabel: {
              display: true,
              labelString: 'Fecha y Hora'
            }
          }
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Valor'
            }
          }
        ]
      }
    };
  
    return <Line data={data} options={options} />;
  };
  
  export default HistoryDataLog;
  