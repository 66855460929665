import React, { Component } from 'react';

class StatusIndicatorFalse extends Component {
  componentDidMount() {
    // Cargar el script dinámicamente cuando el componente se monta
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js';
    script.async = true;
    document.head.appendChild(script);
  }

  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {/* Agrega el elemento lottie-player */}
        <lottie-player
          src="https://lottie.host/9c143a4d-7ae0-41d5-9c8a-72d159b32711/bhFRMa5Cit.json"
          background="transparent"
          speed="1"
          style={{ width: '30px', height: '30px' }} // Establece los estilos como objeto de estilo de React
          direction="1"
          mode="normal"
          loop
          autoplay
        ></lottie-player>
      </div>
    );
  }
}

export default StatusIndicatorFalse;
