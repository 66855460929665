import React, { Component, useState } from 'react';
import swal from 'sweetalert';
import { Button, TextField, Link } from '@material-ui/core';
import './Login.css'; // Agrega estilos CSS personalizados si es necesario
import Particles from 'react-particles-js';


const axios = require('axios');
const bcrypt = require('bcryptjs');
var salt = bcrypt.genSaltSync(10);



export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  login = () => {

    const pwd = bcrypt.hashSync(this.state.password, salt);

    axios.post('https://api.jababox.com/login', {
      username: this.state.username,
      password: pwd,
    }).then((res) => {
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('user_id', res.data.id);
      this.props.history.push('/dashboard');
    }).catch((err) => {
      if (err.response && err.response.data && err.response.data.errorMessage) {
        swal({
          text: err.response.data.errorMessage,
          icon: "error",
          type: "error"
        });
      }
    });
  }




  
  render() {
    return (
      
      <div className="login-container">
         <div className="particles-container">
                <Particles
            params={{
              "particles": {
                  "number": {
                      "value": 80
                  },
                  "size": {
                      "value": 3
                  }
              },
              "interactivity": {
                  "events": {
                      "onhover": {
                          "enable": false,
                          "mode": "repulse"
                      }
                  }
              }
          }} />
          </div>
          <div className="content-container">
        <div className="logo-container">
          <img
            src="https://cloud.jababox.com/images/user_login.png"
            alt="login FPT controller"
            className="logo"
          />
          <h2 className="title">Acceso a JABADASH</h2>
        </div>

        <div className="form-container">
          <TextField
            id="username"
            type="text"
            autoComplete="off"
            name="username"
            value={this.state.username}
            onChange={this.onChange}
            label="Nombre de usuario"
            variant="outlined"
            fullWidth
            required
          />
          <br />
          <br />
          <TextField
            id="password"
            type="password"
            autoComplete="off"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
            label="Contraseña"
            variant="outlined"
            fullWidth
            required
          />
          <br />
          <br />
          <Button
            className="button_style"
            variant="contained"
            color="primary"
            size="small"
            disabled={this.state.username === '' || this.state.password === ''}
            onClick={this.login}
          >
            Acceso
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link href="/register" className="register-link">
            Registro
          </Link>
        </div>
      </div>
      <div className="footer">
      <p>© 2023 JABADASH powered by JABABOX. Todos los derechos reservados.</p>
      </div>
      </div>

    );
  }
}