import React, { Component, useState, useEffect} from 'react';

import  QrReader from "react-qr-reader";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import LiveIndicator from './LiveIndicator'; 
import StatusIndicatorTrue from './StatusIndicatorTrue'; 
import StatusIndicatorFalse from './StatusIndicatorFalse'; 
import HistoryDataLog from './HistoryDataLog'; 


import "leaflet/dist/leaflet.css";


import {
  Button, TextField, Dialog, DialogActions, LinearProgress,
  DialogTitle, DialogContent, TableBody, Table,
  TableContainer, TableHead, TableRow, TableCell, Box, Typography, Grid, Paper, Divider, Switch, IconButton, Chip
} from '@material-ui/core';


import { Pagination, Alert, AlertTitle } from '@material-ui/lab';

import ChangeHighlight from "react-change-highlight";

import { Power, PowerOff, SettingsInputAntenna, Input, Tune } from '@material-ui/icons';
import BatteryGauge from 'react-battery-gauge'; 

import 'react-spinner-animated/dist/index.css'
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility';





//BATTERY

import { FcFullBattery } from "react-icons/fc";
import { FcHighBattery } from "react-icons/fc";
import { FcMiddleBattery } from "react-icons/fc";
import { FcLowBattery } from "react-icons/fc";
import { FcEmptyBattery } from "react-icons/fc";

import { BsLightningFill } from "react-icons/bs";
import { CiTempHigh } from "react-icons/ci";
import { RiDirectionLine } from "react-icons/ri";


//SOLAR
import { BsSunFill } from "react-icons/bs";
import { BsFillCloudMoonFill } from "react-icons/bs";

import { BsQrCodeScan } from "react-icons/bs";

//STREET LAMP
import { FcIdea } from "react-icons/fc";
import { FcNoIdea } from "react-icons/fc";
import { BsLightbulbOff } from "react-icons/bs";

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import SettingsIcon from '@material-ui/icons/Settings';

import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import SettingsPowerOutlinedIcon from '@material-ui/icons/SettingsPowerOutlined';
import CachedIcon from '@material-ui/icons/Cached';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


import swal from 'sweetalert';
import './Login.css'; // Agrega estilos CSS personalizados si es necesario

import './dashboardStyles.css'; // Agrega estilos CSS personalizados si es necesario

const axios = require('axios');







const styles = {
  section: {
    marginBottom: '16px',
  },
  sectionHeader: {
    marginBottom: '8px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  cardItem: {
    flex: '1 1 calc(50% - 8px)',
    boxSizing: 'border-box',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
    boxShadow: 1,
    borderRadius: '8px',
    padding: '12px',
  },
  icon: {
    marginBottom: '4px',
    color: '#4A90E2',
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    color: '#555',
    marginBottom: '4px',
  },
  subtitle: {
    color: '#9B9B9B',
  },
};


const customIcon = new L.Icon({
  iconUrl: '/icons/jabaonline3.png', // Ruta a la imagen de tu ícono personalizado
  iconSize: [32, 32], // Tamaño del ícono
  iconAnchor: [16, 32], // Punto del ícono que corresponde a la posición del marcador
  popupAnchor: [0, -32] // Punto del popup relativo al icono
});



// APP COMPLETA
export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      openProductModal: false,
      openProductModal1: false,
      openProductEditModal: false,
      openGraphDialog: false,
      id: '',
      name: '',
      sn: '',
      verification_code: '',
      battery: '',
      solar: '',
      power_supply: '',
      status: '',
      fileName: '',
      page: 1,
      search: '',
      products: [],
      pages: 0,
      loading: false,
      qrScannerOpen: false,
      location: '',
      mapCenter: [0, 0],
      isLive: false,
      history:[],
      auxr1: false,
      auxr2: false,
      ind8: true,
      ind9: true,
      mainload: false,
      lat: '',
      long: '',
      simOperator: '',
      lastEvent: '',
      selectedProductSerial: null, // Identificador único del producto seleccionado
      selectedProduct: null, // Datos del producto seleccionado
      lastEvent2: props.selectedProduct?.lastEvent,
      
    };
  }







//MAPA 

handleMapClick = async (e) => {
  const { lat, lng } = e.latlng;
  this.setState({ mapCenter: [lat, lng] });

  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    );
    const data = await response.json();
    if (data.address) {
      const address = `${data.address.road || ''} ${data.address.house_number || ''}, ${data.address.city || ''}, ${data.address.country || ''}`;
      this.setState({ location: address });
    }
  } catch (error) {
    console.error('Error fetching address:', error);
  }
};





  componentDidMount = () => {
    let token = localStorage.getItem('token');
    if (!token) {
      this.props.history.push('/');
    } else {
      this.setState({ token: token }, () => {
        this.getProduct();
      });
    }
  }


  getProductHide = () => {

    this.setState({ loading: false });

    let data = '?';
    data = `${data}page=${this.state.page}`;
    if (this.state.search) {
      data = `${data}&search=${this.state.search}`;
    }
    axios.get(`https://api.jababox.com/get-product${data}`, {
      headers: {
        'token': this.state.token
      }
    }).then((res) => {
      this.setState({ loading: false, products: res.data.products, pages: res.data.pages });
      //console.log(data);
    }).catch((err) => {
      swal({
        text: err.response.data.errorMessage,
        icon: "error",
        type: "error"
      });
      this.setState({ loading: false, products: [], pages: 0 }, () => { });
    });
  }

//BETA 

handleRefresh = () => {
  
  this.getProductHide(); // Actualiza la lista de productos
  this.getProductDetails(this.state.selectedProductSerial); // Actualiza los detalles del producto seleccionado
};

locatemap = (lat, long) => {
  const url = 'https://maps.google.com/?q=' + lat + ',' + long; // Construir la URL de Google Maps
  window.open(url, '_blank'); // Abrir la URL en una nueva pestaña
};


dialogReload = () => {
  swal({
    text: "Okey! Refrescaré el estado de los dispositivos en tiempo real hasta 1 minuto",
    icon: "success",
    type: "success"
  });
  this.handleRefresh();
  
  
  // Limpia el intervalo anterior, si existe
  if (this.interval) {
    clearInterval(this.interval);
  }

  // Establece un nuevo intervalo
  this.interval = setInterval(this.handleRefresh, 1000);
  this.setState({ isLive: true });
  // Detiene el intervalo después de 60 segundos
  setTimeout(() => {
    clearInterval(this.interval);
    this.setState({ isLive: false });
    swal({
      text: "El refresco de estado en tiempo real se ha detenido, vuelva a pulsar el botón 'REFRESH' si lo desea.",
      icon: "info",
      type: "info"
    });
  }, 60000); // 60 segundos en milisegundos
}








  handleProductOpen1 = (serial) => {
    this.setState({ 
      openProductModal1: true,
      selectedProductSerial: serial
    }, () => {
      this.getProductDetails(serial); // Llama a la función para obtener detalles del producto
    });
  };
  
  getProductDetails = (serial) => {
    // Encuentra el producto en la lista de productos usando el identificador único
    const product = this.state.products.find(p => p.sn === serial);
  
    // Actualiza el estado con los detalles del producto seleccionado
    this.setState({
      selectedProduct: product
    });
  };
  


  //END BETA


  getProduct = () => {

    this.setState({ loading: true });

    let data = '?';
    data = `${data}page=${this.state.page}`;
    if (this.state.search) {
      data = `${data}&search=${this.state.search}`;
    }
    axios.get(`https://api.jababox.com/get-product${data}`, {
      headers: {
        'token': this.state.token
      }
    }).then((res) => {
      this.setState({ loading: false, products: res.data.products, pages: res.data.pages });
      //console.log(data);
    }).catch((err) => {
      swal({
        text: err.response.data.errorMessage,
        icon: "error",
        type: "error"
      });
      this.setState({ loading: false, products: [], pages: 0 }, () => { });
    });
  }

  deleteProduct = (id, sn) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 

    swal({
      title: "¿Estás seguro?",
      text: "Una vez borrado, no podrá recuperar los datos!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/delete-product', {
            id: id,
            mac:sn
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación cancelada",
            icon: "info"
          });
        }
      });
  }


  //CONTROL BIDIERECTIONAL

  handleReboot = (sn) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 

    swal({
      title: "¿Estás seguro?",
      text: "Una vez de ejecutado el reinicio, las cargas del sistema JABABOX se apagarán y encenderán completamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: "mainrload_reboot"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación de reinicio cancelada",
            icon: "info"
          });
        }
      });
  }


  handlePowerOn = (sn) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 

    swal({
      title: "¿Estás seguro?",
      text: "Una vez de ejecutado el encendido, las cargas del sistema JABABOX se encenderán completamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: "mainrload_0"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación de reinicio cancelada",
            icon: "info"
          });
        }
      });
  }



  handlePowerOff = (sn) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 

    swal({
      title: "¿Estás seguro?",
      text: "Una vez de ejecutado el apagado, las cargas del sistema JABABOX se apagarán completamente!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: "mainrload_1"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación de reinicio cancelada",
            icon: "info"
          });
        }
      });
  }


  handlePowerAuxr1 = (sn, auxr1) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 
const msg = auxr1 ? "Una vez de ejecutado el APAGADO, las cargas del sistema JABABOX se APAGARÁN completamente!" : "Una vez de ejecutado el ENCENDIDO, las cargas del sistema JABABOX se ENCENDERÁN completamente!";
    
swal({
      title: "¿Estás seguro?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: auxr1 ? "auxr1_0" : "auxr1_1"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación cancelada",
            icon: "info"
          });
        }
      });
  }


  handlePowerAuxr2 = (sn, auxr2) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 
const msg = auxr2 ? "Una vez de ejecutado el APAGADO, las cargas del sistema JABABOX se APAGARÁN completamente!" : "Una vez de ejecutado el ENCENDIDO, las cargas del sistema JABABOX se ENCENDERÁN completamente!";
    
swal({
      title: "¿Estás seguro?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: auxr2 ? "auxr2_0" : "auxr2_1"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación cancelada",
            icon: "info"
          });
        }
      });
  }


  handlePowerMainload = (sn, mainload) => {
    //NOTIFICATION: YOU ARE SURE TO DELETE PRODUCT? 
const msg = mainload ? "Una vez de ejecutado el APAGADO, las cargas del sistema JABABOX se APAGARÁN completamente!" : "Una vez de ejecutado el ENCENDIDO, las cargas del sistema JABABOX se ENCENDERÁN completamente!";
    
swal({
      title: "¿Estás seguro?",
      text: msg,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      //YES: IF THEY CLICK YES DO THIS: 
      .then((willDelete) => {
        if (willDelete) {

          axios.post('https://api.jababox.com/product-control', {
            
            sn:sn,
            action: mainload ? "mainload_0" : "mainload_1"
          }, {
            headers: {
              'Content-Type': 'application/json',
              'token': this.state.token
            }
          })
            .then((res) => {

              swal({
                title: res.data.title,
                icon: "success",
                type: "success"
              });

              this.setState({ page: 1 }, () => {
                this.pageChange(null, 1);
              });
            }).catch((err) => {
              swal({
                title: err.response.data.errorMessage,
                icon: "error",
                type: "error"
              });
            });
        }

        else {
          swal({
            title: "Operación cancelada",
            icon: "info"
          });
        }
      });
  }




  consoleClick = (data) => {
    console.log(data.status.status);
    // Resto del código...
  }






  
  // handle table click

handleCellClick = (data) => {
    //console.log(data);
    this.getProduct();

    

    this.setState({
      openProductModal1: true,
      id: data.id,
      name: data.name,
      sn: data.sn,
      verification_code: data.verification_code,
      battery: data.battery,
      solar: data.solar,
      fileName: '',
      power_supply: data.power_supply,
      last_solar: data.last_solar,
      last_power_supply: data.last_power_supply,
      status:
      {

        name: data.status.name,
        serial: data.status.serial,
        timestamp: data.status.timestamp,
        status: data.status.status,
        statushtml: data.status.statushtml,
        totalBytes: data.status.totalBytes,
        usedBytes: data.status.usedBytes,
        hddstatus: data.status.hddstatus,
        hddstatushtml: data.status.hddstatushtml,
        photourl: data.status.photourl,
        photohtml: data.status.photohtml
        
      },
      lat: data.costumer_info.lat,
      lng: data.costumer_info.lng,
      history: data.history,
      auxr1: data.auxr1,
      auxr2: data.auxr2,
      ind8: data.ind8,
      ind9: data.ind9,
      mainload: data.mainload,
      lat: data.lat,
      long: data.long,
      simOperator: data.simOperator,
      selectedProductSerial: data.sn,
      lastEvent: data.lastEvent
    

    }, () => {
      // Este callback se ejecuta después de que el estado ha sido actualizado
      console.log(this.state);
      this.getProductDetails(data.sn);
  });
    this.getProduct();
  }; 




  handlegrafClick = (data) => {
    //console.log(data);
    this.getProduct();

    

    this.setState({
      openGraphDialog: true,
      id: data.id,
      name: data.name,
      sn: data.sn,
      verification_code: data.verification_code,
      battery: data.battery,
      solar: data.solar,
      fileName: '',
      power_supply: data.power_supply,
      last_solar: data.last_solar,
      last_power_supply: data.last_power_supply,
      status:
      {

        name: data.status.name,
        serial: data.status.serial,
        timestamp: data.status.timestamp,
        status: data.status.status,
        statushtml: data.status.statushtml,
        totalBytes: data.status.totalBytes,
        usedBytes: data.status.usedBytes,
        hddstatus: data.status.hddstatus,
        hddstatushtml: data.status.hddstatushtml,
        photourl: data.status.photourl,
        photohtml: data.status.photohtml
        
      },
      lat: data.costumer_info.lat,
      lng: data.costumer_info.lng,
      history: data.history,
      auxr1: data.auxr1,
      auxr2: data.auxr2,
      ind8: data.ind8,
      ind9: data.ind9,
      mainload: data.mainload,
      lat: data.lat,
      long: data.long,
      simOperator: data.simOperator,
      lastEvent: data.lastEvent
    

    });
    this.getProduct();
  };





  pageChange = (e, page) => {
    this.setState({ page: page }, () => {
      this.getProduct();
    });
  }

  logOut = () => {
    localStorage.setItem('token', null);
    this.props.history.push('/');
  }

  reload = () => {
    swal({
      text: "Okey! Refrescaré el estado de los dispositivos en tiempo real hasta 1 minuto",
      icon: "success",
      type: "success"
    });
    this.getProductHide();
    
    
    // Limpia el intervalo anterior, si existe
    if (this.interval) {
      clearInterval(this.interval);
    }
  
    // Establece un nuevo intervalo
    this.interval = setInterval(this.getProductHide, 1000);
    this.setState({ isLive: true });
    // Detiene el intervalo después de 60 segundos
    setTimeout(() => {
      clearInterval(this.interval);
      this.setState({ isLive: false });
      swal({
        text: "El refresco de estado en tiempo real se ha detenido, vuelva a pulsar el botón 'REFRESH' si lo desea.",
        icon: "info",
        type: "info"
      });
    }, 60000); // 60 segundos en milisegundos
  }
  
  onChange = (e) => {
    if (e.target.files && e.target.files[0] && e.target.files[0].name) {
      this.setState({ fileName: e.target.files[0].name }, () => { });
    }
    this.setState({ [e.target.name]: e.target.value }, () => { });
    if (e.target.name == 'search') {
      this.setState({ page: 1 }, () => {
        this.getProduct();
      });
    }
  };

  addProduct = () => {
    //const fileInput = document.querySelector("#fileInput");
    const file = new FormData();
    //file.append('file', fileInput.files[0]);
    file.append('name', this.state.name);
    file.append('sn', this.state.sn);
    file.append('verification_code', this.state.verification_code);
    this.setState({ loading: true });
    
    const status = {
      'name': this.state.name,
      'serial': this.state.sn,
      'verification_code': this.state.verification_code

    };

    file.append('status', status);

    axios.post('https://api.jababox.com/add-product', file, {
      headers: {
        'content-type': 'multipart/form-data',
        'token': this.state.token
      }
    }).then((res) => {
      if (res.status == 202) {
        swal({
          text: res.data.title,
          icon: "warning",
          type: "warning"
        });
        this.handleProductClose();
        this.setState({ loading: false });
        this.setState({ name: '', sn: '', verification_code: '', page: 1  }, () => {
        this.getProduct();
      });
      }

      else {
        swal({
          text: res.data.title,
          icon: "success",
          type: "success"
        });
        this.setState({ loading: false });
        this.handleProductClose();
        this.setState({ name: '', sn: '', verification_code: '', page: 1   }, () => {
        this.getProduct();
      });}
    }).catch((err) => {
      swal({
        text: err.response.data.errorMessage,
        icon: "error",
        type: "error"
      });
      this.handleProductClose();
      this.setState({ loading: false });
    });

  }

  updateProduct = () => {
    //const fileInput = document.querySelector("#fileInput");
    const file = new FormData();

    file.append('name', this.state.name);
    file.append('sn', this.state.sn);

    axios.post('https://api.jababox.com/update-product', file, {
      headers: {
        'content-type': 'multipart/form-data',
        'token': this.state.token
      }
    }).then((res) => {

      swal({
        text: res.data.title,
        icon: "success",
        type: "success"
      });

      this.handleProductEditClose();
      this.setState({ name: '', sn: '', battery: '', solar: '', power_supply: '' }, () => {
        this.getProduct();
      });
    }).catch((err) => {
      swal({
        text: err.response.data.errorMessage,
        icon: "error",
        type: "error"
      });
      this.handleProductEditClose();
    });

  }



handleQRScan = (data) => {
    if (data) {
      this.setState({ sn: data, qrScannerOpen: false });
    }};



  handleProductOpen = () => {
    this.setState({
      openProductModal: true,
      id: '',
      name: '',
      sn: '',
      verification_code:'',
      battery: '',
      solar: '',
      fileName: '',
      power_supply: ''
    });
  };

  handleProductClose = () => {
    this.setState({ openProductModal: false });
  };
  handleProductClose1 = () => {
    this.setState({ openProductModal1: false });
    clearInterval(this.interval);

  };

  handleProductEditOpen = (data) => {
    this.setState({
      openProductEditModal: true,
      id: data.id,
      name: data.name,
      sn: data.sn,
      battery: data.battery,
      solar: data.solar,
      fileName: data.fileName,
      power_supply: data.power_supply
    });
  };

  handleProductEditClose = () => {
    this.setState({ openProductEditModal: false });
  };

  errorMesage = () => {
    return { msg: <Alert variant="outlined" severity="error">From function</Alert> };
  };


//QR CODE READER


toggleQRScanner = () => {
  this.setState((prevState) => ({
    qrScannerOpen: !prevState.qrScannerOpen,
  }));
};


handleOpenGraphDialog = () => {
  this.setState({ 
    openGraphDialog: true,
    openProductModal1: false

   });
};

handleCloseGraphDialog = () => {
  this.setState({ openGraphDialog: false });
};



  render() {

  // Desestructuramos el estado
  const { selectedProduct } = this.state;

  // Definimos los colores de los botones basándonos en las propiedades del producto seleccionado
  const buttonColor_auxr1 = selectedProduct?.auxr1 ? '#4CAF50' : '#F44336';
  const buttonColor_auxr2 = selectedProduct?.auxr2 ? '#4CAF50' : '#F44336';
  const buttonColor_mainload = selectedProduct?.mainload ? '#4CAF50' : '#F44336';




    return (
      <div>
        {this.state.loading && <LinearProgress size={40} />}
        <div>
          <h2>JABABOX PLATFORM</h2>
          <Button
            className="button_style"
            variant="contained"
            color="primary"
            size="small"
            onClick={this.handleProductOpen}
            startIcon={<AddCircleIcon />}
            style={{ margin: '5px' }}
          >
            Añadir Sistema
          </Button>
          <Button
            className="button_style"
            variant="contained"
            size="small"
            onClick={this.settings}
            style={{ backgroundColor: '#F39C12',color: 'white', margin: '5px' }} // Color ambar cream
            startIcon={<SettingsIcon />}

          >
            SETTINGS  
          </Button>
          <Button
            className="button_style"
            variant="contained"
            size="small"
            onClick={this.reload}
            startIcon={<RefreshIcon />}
            style={{ margin: '5px'}}
          >
            Refresh
          </Button>

          <Button
            className="button_style"
            variant="contained"
            size="small"
            onClick={this.logOut}
            style={{ backgroundColor: '#FF6B6B', color: 'white', margin: '5px' }}
            startIcon={<ExitToAppIcon />}
        
          >
            Log Out
          </Button>

          {this.state.isLive ? <LiveIndicator /> : null}
        </div>

        {/* Edit Product */}
        <Dialog
          open={this.state.openProductEditModal}
          onClose={this.handleProductClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Editar Sistema</DialogTitle>
          <DialogContent>
            <TextField
              id="standard-basic"
              type="text"
              autoComplete="off"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              placeholder="Nombre sistema"
              required
            /><br />
            <TextField
              id="standard-basic"
              type="text"
              autoComplete="off"
              name="sn"
              disabled={true}
              value={this.state.sn}
              onChange={this.onChange}
              placeholder="Número de serie"
              required
            />
            <br /><br />
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleProductEditClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={this.state.name == '' || this.state.sn !== this.state.sn}
              onClick={(e) => this.updateProduct()} color="primary" autoFocus>
              Editar Sistema
            </Button>
            
            
          </DialogActions>
        </Dialog>

        {/* check Product */}
        <Dialog
        open={this.state.openProductModal1}
        onClose={this.handleProductClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true} // Hace que el diálogo use todo el ancho disponible
        maxWidth="lg" // Puedes ajustar el ancho máximo del diálogo
      >
      <DialogTitle id="alert-dialog-title">
        Estado del sistema: <b>{this.state.name}</b> {this.state.isLive ? <LiveIndicator /> : null}
          
        <div style={{ marginTop: '1px' }}>
          <i style={{ display: 'inline' }}>
            Último evento recibido: 
            <ChangeHighlight>
              <span ref={React.createRef()} style={{ display: 'inline' }}>{this.state.selectedProduct?.lastEvent}</span>
            </ChangeHighlight>
          </i>
        </div>
      </DialogTitle>
        

       
        <DialogContent style={{ padding: '0', display: 'flex', height: '100vh' }}>
    <Grid container style={{ height: '100%' }}>
      
      {/* Columna izquierda: Mapa */}
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ height: '100%', width: '100%' }}>
        {this.state.lat && this.state.long ? (
            <MapContainer
              style={{ height: '100%', width: '100%' }}
              center={[this.state.lat, this.state.long]}
              zoom={16}
              scrollWheelZoom={false}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://cloud.jababox.com">OpenStreetMap</a> Jababox'
              />
              <Marker position={[this.state.lat, this.state.long]} icon={customIcon}>
                <Popup>
                  {this.state.name || 'Ubicación del dispositivo'}
                </Popup>
              </Marker>
            </MapContainer>
          ) : (
            <div>No se pudo cargar el mapa porque las coordenadas no están disponibles.</div>
          )}
        </div>
      </Grid>
      
      {/* Columna derecha: Información */}
      <Grid item xs={12} md={6} style={{ overflowY: 'auto', padding: '24px' }}>
        <Box style={{ backgroundColor: '#f8f9f9', borderRadius: '24px' }}>
          <Typography variant="h6" style={{ marginBottom: '16px'}}>
            Información del Dispositivo
          </Typography>

          <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px' }}>
            <Typography
              variant="subtitle1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Centra el contenido horizontalmente
                marginBottom: '8px',
                textAlign: 'center', // Centra el texto en el componente
              }}
            >
              
              {this.state.selectedProduct?.status.status === 'true' ? <StatusIndicatorTrue />  : <StatusIndicatorFalse />}
              
              {this.state.selectedProduct?.status.status === 'true' ? "  En Línea"  : "  Fuera de línea"}
              
            </Typography>
          </Paper>

          <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px'  }}>
            <Typography
              variant="subtitle1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Centra el contenido horizontalmente
                marginBottom: '8px',
                textAlign: 'center', // Centra el texto en el componente
              }}
            >
              <SettingsInputAntenna style={{ marginRight: '8px' }} />
              Operador de la SIM: {this.state.selectedProduct?.simOperator}
            </Typography>
          </Paper>

            {/* Componente de batería */}

            <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px'  }}>
            <Typography
              variant="subtitle1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', // Centra el contenido horizontalmente
                marginBottom: '8px',
                textAlign: 'center', // Centra el texto en el componente
              }}
            >
              <BatteryGauge  value={ isNaN(Number(this.state.selectedProduct?.battery)) ? 0 : Number(this.state.selectedProduct?.battery) }  orientation= 'vertical' size ='100' animated='true' />
              Nivel de Batería: {this.state.selectedProduct?.battery} %
            </Typography>
          </Paper>
              


          <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px'  }}>
        <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Power style={{ marginRight: '8px' }} /> Estado de la Potencia de Entrada
        </Typography>
        
        <Grid container spacing={2}>
          {/* Estado de la Fuente de Alimentación */}
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 'bold' }}
            >
              Fuente de Alimentación:
              <span style={{ color: this.state.selectedProduct?.power_supply === 'true' ? '#4CAF50' : '#808b96', display: 'block' }}>
                {this.state.selectedProduct?.power_supply === 'true' ? 'Conectada' : 'Desconectada'}
              </span>
            </Typography>
            <Box
              style={{
                width: 55,
                height: 55,
                display: 'inline-block',
                animation: this.state.selectedProduct?.power_supply === 'true' ? 'fade-in 2s infinite alternate' : 'fade-in 2s infinite alternate',
                backgroundImage: this.state.selectedProduct?.power_supply === 'true'
                  ? 'url("/icons/lamp_on.png")' 
                  : 'url("/icons/lamp_off.png")',
                backgroundSize: 'cover',
                marginRight: '8px'
              }}
            />
            <Typography
              variant="body2"
              style={{ fontStyle: 'italic', color: '#757575', marginTop: '8px' }} // Texto en cursiva y color gris
            >
              {this.state.selectedProduct?.last_power_supply}
            </Typography>
          </Grid>

          {/* Estado de la Energía Solar */}
          <Grid item xs={12} sm={6}>
            <Typography
              variant="subtitle2"
              style={{ fontWeight: 'bold' }}
            >
              Energía Solar:
              <span style={{ color: this.state.selectedProduct?.solar === 'true' ? '#FF9800' : '#808b96', display: 'block' }}>
                {this.state.selectedProduct?.solar === 'true' ? 'Cargando' : 'Sin sol'}
              </span>
            </Typography>
            <Box
              style={{
                width: 55,
                height: 55,
                display: 'inline-block',
                animation: this.state.selectedProduct?.solar === 'true' ? 'rotate-sun 3s infinite linear' : '',
                backgroundImage: this.state.selectedProduct?.solar === 'true'
                  ? 'url("/icons/sun.png")' 
                  : 'url("/icons/moon.png")',
                backgroundSize: 'cover',
                marginRight: '8px'
              }}
            />
            <Typography
              variant="body2"
              style={{ fontStyle: 'italic', color: '#757575', marginTop: '8px' }} // Texto en cursiva y color gris
            >
              {this.state.last_solar}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px'  }}>
        <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <FcIdea style={{ marginRight: '8px' }} /> Estado de las Salidas
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Relé Auxiliar 1</Typography>
            
                        <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handlePowerAuxr1(this.state.selectedProduct?.sn,this.state.selectedProduct?.auxr1)}
                          style={{ color: buttonColor_auxr1, borderColor: buttonColor_auxr1, margin: '5px' }}
                          startIcon={<PowerSettingsNewOutlinedIcon />}
                        >
                          {this.state.selectedProduct?.auxr1 ? "Encendido" : "Apagado" }
                        </Button>           


          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Relé Auxiliar 2</Typography>
                      <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handlePowerAuxr2(this.state.selectedProduct?.sn ,this.state.selectedProduct?.auxr2)}
                          style={{ color: buttonColor_auxr2, borderColor: buttonColor_auxr2, margin: '5px' }}
                          startIcon={<PowerSettingsNewOutlinedIcon />}
                        >
                          {this.state.selectedProduct?.auxr2 ? "Encendido" : "Apagado" }
                        </Button> 
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Carga Principal</Typography>
                        <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handlePowerMainload(this.state.selectedProduct?.sn,this.state.selectedProduct?.mainload )}
                          style={{ color: buttonColor_mainload, borderColor: buttonColor_mainload, margin: '5px' }}
                          startIcon={<PowerSettingsNewOutlinedIcon />}
                        >
                          {this.state.selectedProduct?.mainload ? "Encendido" : "Apagado" }
                        </Button> 
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} style={{ padding: '16px', marginBottom: '24px', borderRadius: '20px' }}>
        <Typography variant="h6" style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Input style={{ marginRight: '8px' }} /> Estado de las Entradas del Sistema
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Entrada 8</Typography>
            <Switch checked={this.state.selectedProduct?.ind8} color="primary" />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2">Entrada 9</Typography>
            <Switch checked={this.state.selectedProduct?.ind9} color="primary" />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} style={{ padding: '20px', borderRadius: '20px' }}>
      <Typography 
        variant="h6" 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '20px', 
          fontWeight: 600 
        }}
      >
        <Tune style={{ marginRight: '10px', color: '#1976D2' }} /> 
        Visualización de Estados
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" style={{ color: '#333' }}>Relé Auxiliar 1</Typography>
          <Box 
            style={{ 
              height: '24px', 
              borderRadius: '12px', 
              backgroundColor: this.state.selectedProduct?.auxr1 ? '#4CAF50' : '#F44336',
              transition: 'background-color 0.3s ease'
            }} 
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" style={{ color: '#333' }}>Relé Auxiliar 2</Typography>
          <Box 
            style={{ 
              height: '24px', 
              borderRadius: '12px', 
              backgroundColor: this.state.selectedProduct?.auxr2 ? '#4CAF50' : '#F44336',
              transition: 'background-color 0.3s ease'
            }} 
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" style={{ color: '#333' }}>Carga Principal</Typography>
          <Box 
            style={{ 
              height: '24px', 
              borderRadius: '12px', 
              backgroundColor: this.state.selectedProduct?.mainload ? '#4CAF50' : '#F44336',
              transition: 'background-color 0.3s ease'
            }} 
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: '20px 0', backgroundColor: '#BDBDBD' }} />

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" style={{ color: '#333' }}>Entrada 8</Typography>
          <Box 
            style={{ 
              height: '24px', 
              borderRadius: '12px', 
              backgroundColor: this.state.selectedProduct?.ind8 ? '#4CAF50' : '#F44336',
              transition: 'background-color 0.3s ease'
            }} 
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" style={{ color: '#333' }}>Entrada 9</Typography>
          <Box 
            style={{ 
              height: '24px', 
              borderRadius: '12px', 
              backgroundColor: this.state.selectedProduct?.ind9 ? '#4CAF50' : '#F44336',
              transition: 'background-color 0.3s ease'
            }} 
          />
        </Grid>
      </Grid>
    </Paper>
          
        </Box>
      </Grid>
      {/* Contenido existente */}
      {this.state.openChart && (
            <HistoryDataLog
              historyData={<HistoryDataLog
                historyData={this.state.selectedProduct?.history}
              />
              }

            />
          )}
    </Grid>
  </DialogContent>
             
            <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button
              onClick={() => this.locatemap(this.state.lat, this.state.long)}
              style={{ color: '#ffffff', backgroundColor: '#4a80f5', textTransform: 'none'}}
              startIcon={<RiDirectionLine />}
            >
              Cómo llegar
            </Button>

            <div>
              {/* Contenedor para alinear los otros botones a la derecha */}
              <Button onClick={this.dialogReload} color="primary">
                Refrescar
              </Button>
              <Button onClick={this.handleOpenGraphDialog} color="primary">
                Ver Gráfico
              </Button>
            </div>
          </DialogActions>
      </Dialog>

              {/* ... gráfico */}
      <Dialog
        open={this.state.openGraphDialog}
        onClose={this.handleCloseGraphDialog}
        aria-labelledby="graph-dialog-title"
        aria-describedby="graph-dialog-description"
        fullWidth={true} // Esto hace que el diálogo sea del ancho completo del contenedor
        maxWidth="md" // Puedes elegir entre 'xs', 'sm', 'md', 'lg', 'xl' o false
      >
        <DialogTitle id="graph-dialog-title">Gráfico Histórico</DialogTitle>
        <DialogContent>
          <HistoryDataLog
            historyData={this.state.history}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseGraphDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
          




        {/* Add Product */}
        <Dialog
          open={this.state.openProductModal}
          onClose={this.handleProductClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Añadir Sistema JABABOX</DialogTitle>
          <div className='logo-container'><img
            src="https://cloud.jababox.com/images/jababox.png"
            alt="jababox add"
            className="logo"
          /></div>
          <DialogContent>

            <TextField
              id="standard-basic"
              type="text"
              autoComplete="off"
              name="name"
              value={this.state.name}
              onChange={this.onChange}
              placeholder="Nombre sistema"
              required
              InputProps={{
                endAdornment: this.state.name && (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ),
              }}
            /><br />
            <TextField
              id="standard-basic"
              type="text"
              autoComplete="off"
              name="sn"
              value={this.state.sn}
              onChange={this.onChange}
              placeholder="Número de serie"
              required
              style={{width: '60%'}}
              InputProps={{
                endAdornment: this.state.sn && (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ),
              }}
            />
            <IconButton onClick={this.toggleQRScanner}>
              <BsQrCodeScan /> {/* Reemplaza QrCodeScannerIcon con el icono que desees */}
            </IconButton>
          {/* Renderizar el escáner de QR si está abierto */}
          {this.state.qrScannerOpen && (
              <QrReader
                onScan={async (data) => {
                  if (data ) {
                  this.setState({ sn: data }); // Actualizar el estado sn con el código escaneado
                  this.setState({ qrScannerOpen: false });
                  }
                }}
                style={{ width: "250px" }}
                facingMode= { 'environment'} // Cambiar a 'environment' para usar la cámara trasera
                delay={500}
                
              />
            )}
            <br />
            <TextField
              id="standard-basic"
              type="text"
              autoComplete="off"
              name="verification_code"
              value={this.state.verification_code}
              onChange={this.onChange}
              placeholder="Código de verificación"
              required
              InputProps={{
                endAdornment: (
                  // Muestra el icono de verificación si la longitud es igual a 4, de lo contrario, muestra el icono de error
                  this.state.verification_code ? (
                    <CheckCircleIcon style={{ color: 'green' }} />
                  ) : (
                    <ErrorOutlineIcon style={{ color: 'red' }} />
                  )
                ),
              }}
            />
            <br />
            <br />

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleProductClose} color="primary">
              Cancel
            </Button>
            <Button
              disabled={this.state.name == '' || this.state.sn == '' || this.state.verification_code == ''}
              onClick={(e) => this.addProduct()} color="primary" autoFocus>
              Añadir sistema
            </Button>
            
          </DialogActions>
        </Dialog>

        <br />

        <TableContainer>
          <TextField
            id="standard-basic"
            type="search"
            autoComplete="off"
            name="search"
            value={this.state.search}
            onChange={this.onChange}
            placeholder="Search by product name"
            required
          />
          <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>DISPOSITIVO</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>ESTADO</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>NOMBRE</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>SERIAL</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>BATERÍA</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>SOLAR</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>FUENTE</TableCell>
              <TableCell align="center" style={{ fontWeight: 'bold' }}>TEMPERATURA</TableCell>
              <TableCell
                align="center"
                style={{
                  fontWeight: 'bold',
                  maxWidth: '440px',        // Establece el ancho máximo
                  overflow: 'hidden',       // Oculta el contenido que excede el ancho máximo
                  textOverflow: 'ellipsis', // Muestra puntos suspensivos (...) si el contenido es demasiado largo
                  whiteSpace: 'nowrap'     // Evita el ajuste de línea
                }}
              >
                ACCIONES
              </TableCell>
            </TableRow>
          </TableHead>

            
            <TableBody>
              {this.state.products.map((row) => (
                <TableRow key={row.name} style={{ backgroundColor: row.status.status }} >

                <TableCell onClick={(e) => this.handleCellClick(row)} align="center" ><img src={`https://api.jababox.com/${row.image}`} width="70" height="70" /></TableCell>

                  {/* Columna de Estado */}

                  <TableCell onClick={(e) => this.consoleClick(row)} align="center">
                    {row.status.status === 'true' ? <StatusIndicatorTrue /> : <StatusIndicatorFalse />}
                  </TableCell>

                  <TableCell onClick={(e) => this.handleCellClick(row)} align="center" component="th" scope="row" >
                    <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>
                      {row.name}
                    </span>
                   
                  </TableCell>

                  <TableCell onClick={(e) => this.handleCellClick(row)} align="center" >
                    <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>
                      {row.sn}
                    </span>
                    
                  </TableCell>

                  <TableCell onClick={(e) => this.handlegrafClick(row)} align="center">
                  <center>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%)', color: 'gold' }}>
                        {row.solar === 'true' || row.power_supply === 'true' ? <BsLightningFill size={15} /> : <BsLightningFill size={15} style={{ color: 'Gainsboro' }} />}
                      </div>
                      <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>{row.battery}%</span>
                      {row.battery >= 90 ? <FcFullBattery size={50} /> :
                      row.battery >= 70 ? <FcHighBattery size={50} /> :
                      row.battery >= 50 ? <FcMiddleBattery size={50} /> :
                      row.battery >= 15 ? <FcLowBattery size={50} /> :
                      <FcEmptyBattery size={50} />}
                    </div>
                  </center>
                  </TableCell>

                  <TableCell onClick={(e) => this.handlegrafClick(row)} align="center">
                  <div>
                    {row.last_solar ? (
                      <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>{row.last_solar}</span>
                      ) : (
                      <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>No disponible</span>
                          )}
                  </div>
                    <span>
                      {row.solar === 'true' ? <BsSunFill size={50} style={{ color: 'gold' }} /> : <BsFillCloudMoonFill size={50} style={{ color: 'grey' }} />}
                    </span>
                  </TableCell>
                  <TableCell onClick={(e) => this.handlegrafClick(row)} align="center">
                  <div>
                    {row.last_power_supply ? (
                      <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>{row.last_power_supply}</span>
                      ) : (
                      <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1em' }}>No disponible</span>
                          )}
                  </div>
                    <span>
                      {row.power_supply === 'true' ? <FcIdea size={50} /> : <BsLightbulbOff size={40} style={{ color: 'grey' }}/>}
                    </span>
                  </TableCell>

                  {/* Gestión de Temperatura */}
                  <TableCell onClick={(e) => this.handlegrafClick(row)} align="center" style={{ verticalAlign: 'middle' }}>
                    {/* Texto de temperatura centrado y con estilo acorde al icono */}
                    {/* Genera un valor aleatorio entre 30 y 40 y lo muestra */}
                    <span style={{ display: 'block', margin: 'auto', color: 'grey', fontSize: '1.1em' }}>
                      {`${Math.floor(Math.random() * (40 - 30 + 1)) + 30}ºC`}
                    </span>
                    {/* Icono de temperatura alta con estilo personalizado */}
                    <span style={{ display: 'block', margin: 'auto' }}>
                      <CiTempHigh size={45} style={{ color: 'grey' }}/>
                    </span>
                    
                  </TableCell>



                  <TableCell align="center" className="button_container">
                      {/* Botones principales */}
                      <div className="button_group">
                        <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handleReboot(row.sn)}
                          style={{ color: '#FF9800', borderColor: '#FF9800', margin: '5px' }}
                          startIcon={<CachedIcon />}
                        >
                          Reiniciar
                        </Button>

                        <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handlePowerOn(row.sn)}
                          style={{ color: '#4CAF50', borderColor: '#4CAF50', margin: '5px' }}
                          startIcon={<PowerSettingsNewOutlinedIcon />}
                        >
                          Encender
                        </Button>

                        <Button
                          className="button_style"
                          variant="outlined"
                          size="small"
                          onClick={(e) => this.handlePowerOff(row.sn)}
                          style={{ color: '#F44336', borderColor: '#F44336', margin: '5px' }}
                          startIcon={<SettingsPowerOutlinedIcon />}
                        >
                          Apagar
                        </Button>
                      </div>

                      {/* Botones de acción secundaria */}
                      <div className="button_group" style={{ marginTop: '10px' }}>
                        <Button
                          className="button_style"
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={(e) => this.handleProductEditOpen(row)}
                          startIcon={<EditIcon />}
                          style={{ margin: '5px', marginLeft: '15px', marginRight: '15px' }}
                        >
                          Editar
                        </Button>
                        
                        <Button
                          className="button_style"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={(e) => this.deleteProduct(row._id, row.sn)}
                          startIcon={<DeleteIcon />}
                          style={{ margin: '5px', marginLeft: '15px', marginRight: '15px' }}
                        >
                          Borrar
                        </Button>
                      </div>
                    </TableCell>


                </TableRow>

              ))}
            </TableBody>
            
          </Table>
          <br />
          <Pagination count={this.state.pages} page={this.state.page} onChange={this.pageChange} color="primary" />
        </TableContainer>



      </div>
    );
  }
}
